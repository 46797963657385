














































import { Component, Vue } from 'vue-property-decorator';

import ENV from '@/environment';

@Component({})
export default class Services extends Vue {
  public content = {
    title: '',
    details1: '',
    details2: '',
    details3: '',
    dueDate: '',
    details: '',
    documentLinkText: '',
  };
  public env = ENV;

  public contentRu = {
    title: 'Кстати',
    details1:
      'Персональный промокод появляется в приложении после активации аккаунта. Найти свой можно в «Бонусах и промокодах».',
    details2:
      'Другу нужно ввести промокод во время регистрации. А для первой поездки у него есть 90 дней.',
    details3:
      'Промокод не сработает, если у друга уже были поездки и бонусы на счёте.',
    dueDate:
      'Акция действует с 01.07.2024 до момента, пока не будет прекращена. ',
    details:
      'С полными условиями акции, порядком и условиями получения бонусов можно ознакомиться на',
    documentLinkText: 'странице.',
  };
  public contentBy = {
    title: 'Важно знать',
    details1:
      'Поделиться промокодом могут только пользователи с активированным аккаунтом в Anytime.',
    details2:
      'Промокод необходимо ввести при регистрации в сервисе Anytime. Или в боковом меню приложения в разделе “Бонусы и промокоды” до первой поездки. Только в таком случае он сработает. Другу нужно поехать не позднее 90 дней, с момента ввода промокода. Иначе бонус не придет. ',
    details3: '',
    dueDate: '',
    details:
      'С полными условиями акции, порядком и условиями получения бонусов можно ознакомиться на ',
    documentLinkText: 'странице.',
  };

  public psSettings = {
    suppressScrollX: true,
  };

  public mounted(): void {
    if (this.env.REGION === 'by') {
      this.content = { ...this.contentBy };
    } else {
      this.content = { ...this.contentRu };
    }
  }
}
