























import { Component, Vue } from 'vue-property-decorator';
import Layout from './Layout.vue';

import ENV from '@/environment';

@Component({
  components: {
    'v-layout': Layout,
  },
  metaInfo() {
    // eslint-disable-next-line
    if ((this as any).region === 'by') {
      return {
        meta: [
          { charset: 'utf-8' },
          {
            name: 'description',
            content:
              'Приводи друзей в Anytime. Подарим 6 бонусов тебе и другу.',
          },
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1.0',
          },
          {
            property: 'og:title',
            content: 'Приводи друзей и получай 6 бонусов на счет в Anytime.',
          },
          {
            property: 'og:description',
            content: 'Приводи друзей и получай 1000 бонусов на счет в Anytime.',
          },
          { property: 'og:image', content: 'https://delimobil.ru/invite.jpg' },
          { property: 'og:url', content: '' },
        ],
        link: [
          {
            rel: 'shortcut icon',
            type: 'image/x-icon',
            href: '/favicon.png',
          },
        ],
        title: 'Anytime | Приведи друга',
      };
    } else {
      return {
        meta: [
          { charset: 'utf-8' },
          {
            name: 'description',
            content:
              'Приводи друзей в Делимобиль. Подарим 1000 бонусов тебе и другу.',
          },
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1.0',
          },
          {
            property: 'og:title',
            content:
              'Приводи друзей и получай 1000 бонусов на счет в Делимобиле.',
          },
          {
            property: 'og:description',
            content:
              'Приводи друзей и получай 1000 бонусов на счет в Делимобиле.',
          },
          { property: 'og:image', content: 'https://delimobil.ru/invite.jpg' },
          { property: 'og:url', content: '' },
        ],
        link: [
          {
            rel: 'shortcut icon',
            type: 'image/x-icon',
            href: '/favicon.png',
          },
        ],
        title: 'Делимобиль | Приведи друга',
      };
    }
  },
})
export default class App extends Vue {
  public env = ENV;

  get region(): string {
    return this.env.REGION;
  }
}
