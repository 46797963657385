










































import { Component, Vue } from 'vue-property-decorator';

import ENV from '@/environment';

@Component({})
export default class About extends Vue {
  public content = {
    title: '',
    step1: '',
    step2: '',
    step3: '',
    bonus: '',
    dueDate: '',
    details: '',
    documentLinkText: '',
  };
  public env = ENV;

  public contentRu = {
    title: 'Как получить бонусы',
    step1:
      'Отправь другу свой промокод из приложения, и он получит 1000\u00A0бонусов.',
    step2:
      'Когда друг совершит поездку от 100\u00A0₽, тебе придёт 1000\u00A0бонусов.',
    step3: 'Найти свой промокод можно в «Бонусах и промокодах».',
    bonus:
      'Всё точно сработает, если у друга ещё не было поездок и бонусов по другим промокодам.',
    dueDate:
      'Акция действует с\u00A001.07.2024 до момента, пока не будет прекращена. ',
    details:
      'С полными условиями акции, порядком и условиями получения бонусов можно ознакомиться на',
    documentLinkText: 'странице.',
  };
  public contentBy = {
    title: 'Условия участия',
    step1:
      'Поделись с другом своим промокодом из мобильного приложения Anytime. Промокод можно найти в разделе «Бонусы и промокоды».',
    step2:
      'Попроси его ввести твой промокод при регистрации в сервисе Anytime. Или в боковом меню приложения в разделе “Бонусы и промокоды” до первой поездки.',
    step3:
      'Друг получит 6\u00A0бонусов на свой бонусный счет. Когда друг совершит первую поездку, на твой бонусный счет будет начислено 6\u00A0бонусов.',
    bonus: '',
    dueDate: '',
    details:
      'С полными условиями акции, порядком и условиями получения бонусов можно ознакомиться на',
    documentLinkText: 'странице.',
  };

  public psSettings = {
    suppressScrollX: true,
  };

  public mounted(): void {
    if (this.env.REGION === 'by') {
      this.content = { ...this.contentBy };
    } else {
      this.content = { ...this.contentRu };
    }
  }
}
