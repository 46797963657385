import development from './runtime/development.json';
import production from './runtime/production.json';
import stage from './runtime/stage.json';
import productionby from './runtime/production-by.json';
import stageby from './runtime/stage-by.json';

export default {
  development,
  production,
  stage,
  productionby,
  stageby,
};
