










































import { Component, Vue } from 'vue-property-decorator';

import ENV from '@/environment';

@Component({})
export default class Home extends Vue {
  public content = {
    title: '',
    offer1: '',
    offer2: '',
    offer3: '',
    offer4: '',
    dueDate: '',
    details: '',
    documentLinkText: '',
  };
  public env = ENV;

  public contentRu = {
    title: 'Бонусы дружбы',
    offer1: 'Приглашай друзей в Делимобиль и ',
    offer2: 'получай бонусы. По 1000 за каждого, кто введёт твой промокод. ',
    offer3:
      'Такое можно проделать сколько угодно раз и неплохо сэкономить на поездках.',
    offer4:
      'Всё точно сработает, если у друга ещё не было поездок и бонусов по другим промокодам.',
    dueDate:
      'Акция действует с 01.07.2024 до момента, пока не будет прекращена. ',
    details:
      'С полными условиями акции, порядком и условиями получения бонусов можно ознакомиться на ',
    documentLinkText: 'странице.',
  };
  public contentBy = {
    title: 'Больше друзей — больше бонусов',
    offer1: 'Приглашай друзей воспользоваться Anytime и',
    offer2: 'получай 6 рублей на бонусный счет за каждого.',
    offer3: '',
    offer4: '',
    dueDate: '',
    details:
      'С полными условиями акции, порядком и условиями получения бонусов можно ознакомиться в',
    documentLinkText: 'договоре.',
  };

  public mounted(): void {
    if (this.env.REGION === 'by') {
      this.content = this.contentBy;
    } else {
      this.content = this.contentRu;
    }
  }
}
