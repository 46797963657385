














































































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';

import ENV from '@/environment';

@Component({})
export default class Layout extends Vue {
  public env = ENV;
  public opened = false;

  public hovered = false;

  public x = 1000;
  public y = 400;

  public mounted(): void {
    this.initCustomCursor();
  }

  public destroy(): void {
    window.removeEventListener('mousemove', this.updateCoordinates);
  }

  @Watch('$route', { immediate: true, deep: true })
  public onUrlChange(): void {
    setTimeout(this.initCustomCursor, 500);
  }

  public toggle(): void {
    this.opened = !this.opened;
  }

  public close(): void {
    this.opened = false;
  }

  public updateCoordinates(event: MouseEvent): void {
    this.x = event.clientX;
    this.y = event.clientY;
  }

  public initCustomCursor(): void {
    // mouse move cursor event listener magic
    window.addEventListener('mousemove', this.updateCoordinates);

    // bind mouseover and mouseout events to show effects on cursor
    const links = document.getElementsByTagName('a');

    for (const link of links) {
      link.onmouseover = () => {
        this.hovered = true;
      };

      link.onmouseout = () => {
        this.hovered = false;
      };
    }
  }
}
