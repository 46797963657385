import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import Meta from 'vue-meta';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

Vue.component('VuePerfectScrollbar', VuePerfectScrollbar);

Vue.config.productionTip = false;

Vue.use(Meta, { keyName: 'metaInfo' });

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
